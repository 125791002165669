import axios from "axios";

export default async function getLabelContent() {
  let printerLabel = "thermal";

  if (!printerLabel) printerLabel = "dymo";

  const { default: label } = await import(`./label-${printerLabel}.zpl`);

  const response = await axios.get(label);

  return response.data;
}
