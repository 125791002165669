import _ from "underscore";
import getLabelContent from "./labelFetcher";

export default async function prepareLabel(model) {
  const template = await getCachedLabelTemplate();
  return template(model);
}

async function getCachedLabelTemplate() {
  const labelContent = await getLabelContent();
  const labelTemplate = _.template(labelContent);

  return labelTemplate;
}
