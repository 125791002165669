import { dymoLabelPrinter } from "../dymo-label-printing";
import { qzLabelPrinter } from "../qz-label-printing";

export interface LabelPrinter {
  printModel(model: any, settings: any): Promise<void>;
  previewModel(model: any): Promise<void>;
}

export function factory(): LabelPrinter {
  const provider = qzLabelPrinter;
  return provider;
}
