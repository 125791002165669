import axios from "axios";
import { API_KEY } from "modules/app/hooks/useApiKey";

const _axios = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

_axios.interceptors.request.use(
  async (config) => {
    const apiKey = localStorage.getItem(API_KEY);

    if (apiKey) {
      config.headers = {
        ...config.headers,
        Authorization: `ApiKey ${apiKey}`,
      };
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default _axios;
