import { useCallback, useState } from "react";
import { Toast } from "@elastic/eui/src/components/toast/global_toast_list";

const useToasts = () => {
  const [toasts, setToasts] = useState<Toast[]>([]);

  const addToast = useCallback((toast: Toast) => {
    setToasts((toasts) => {
      return [...toasts, toast];
    });
  }, []);

  const removeToast = useCallback((toastId: string) => {
    setToasts((toasts) => {
      const _toasts = [...toasts];

      const index = _toasts.findIndex((toast) => toast.id === toastId);
      _toasts.splice(index, 1);

      return _toasts;
    });
  }, []);

  return { toasts, addToast, removeToast };
};

export default useToasts;
