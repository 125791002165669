import { useCallback, useState } from "react";

export interface FieldState {
  value: any;
  title?: string;
  placeholder?: string;
  disabled?: boolean;
  invalid?: boolean;
  loading?: boolean;
  error?: any;
}

function useField<T>(
  {
    value,
    title = "",
    placeholder = "",
    disabled = false,
    invalid = false,
    loading = false,
    error = null,
  }: FieldState,
  validation?: (val: any) => void
) {
  const [state, _setState] = useState<FieldState>({
    value,
    title,
    placeholder,
    disabled,
    invalid,
    loading,
    error,
  });

  const onChange = useCallback(
    (value: T) => {
      _setState((state) => ({ ...state, value }));
    },
    [_setState]
  );

  const setState = useCallback(
    (nextState: Partial<FieldState>) => {
      _setState((state) => ({ ...state, ...nextState }));
    },
    [_setState]
  );

  const validate = useCallback(() => {
    try {
      validation?.(state.value);
      setState({ invalid: true });
    } catch (error) {
      setState({ invalid: false, error });
      return error;
    }
  }, [_setState, validation]);

  return {
    state,
    onChange,
    setState,
    validate,
  };
}

interface foo<T> {
  (e: T): T;
}

export type UseFieldType = ReturnType<typeof useField>;

export default useField;
