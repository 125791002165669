import { useEffect, useRef, useCallback } from "react";
import { EuiGlobalToastList, EuiPanel, EuiHorizontalRule } from "@elastic/eui";

import { QZPrintProvider } from "modules/services/qz-label-printing";

import useField from "modules/common/hooks/useField";
import Logo from "modules/common/components/Logo";
import ScanField from "modules/common/components/ScanField";

import useBarcode from "./hooks/useBarcode";
import useToasts from "./hooks/useToasts";
import useApiKey from "./hooks/useApiKey";
import styles from "./index.module.css";

const App = () => {
  const ref = useRef<HTMLInputElement>();
  const barcodeField = useField({ value: "", title: "Scan Barcode" });
  const { toasts, addToast, removeToast } = useToasts();
  const validateBarcode = useBarcode();
  const apiKey = useApiKey();

  const onPressEnter = useCallback((barcode: string) => {
    barcodeField.setState({ loading: true, disabled: true });

    setTimeout(async () => {
      const state = await validateBarcode(barcode);

      addToast({
        id: `${Date.now()}`,
        title: state?.title,
        color: state?.status as any,
        text: <>{state?.message}</>,
      });

      setTimeout(() => {
        ref.current?.focus();
      });

      barcodeField.setState({ loading: false, disabled: false });
    }, 1000);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      ref.current?.focus();
    });

    const qzProvider = QZPrintProvider.getInstance();
    qzProvider.initialize();
  }, []);

  return (
    <>
      <EuiGlobalToastList
        toasts={toasts}
        dismissToast={(toast) => removeToast(toast.id)}
        toastLifeTimeMs={5000}
      />
      <main className={styles.home}>
        <div className={styles.homeContent}>
          <EuiPanel className={styles.homePanel}>
            <Logo className={styles.logo} />
            <EuiHorizontalRule />
            {apiKey ? (
              <ScanField
                {...barcodeField}
                inputRef={ref as any}
                onPressEnter={onPressEnter}
              />
            ) : (
              <>Please contact tech support</>
            )}
          </EuiPanel>
        </div>
      </main>
    </>
  );
};

export default App;
