import { useCallback } from "react";
import axios from "modules/common/utils/axios";
import dateOfBirthDateParser from "modules/common/utils/dateOfBirthDateParser";
import * as labelPrinting from "modules/services/label-printing";

export type Status = "success" | "danger" | "warning";

interface Response {
  icon: "success" | "error";
  message?: string | null;
  isSuccess: boolean;
  name: string | null;
  requisitionNumber: string | null;
  dateOfBirth: string | null;
  orderCreated: string | null;
  testTypeName: string | null;
  printerName: string | null;
  printNewLabel: boolean;
}

interface Result {
  status?: Status;
  title?: string;
  message?: string;
}

function useBarcode() {
  const validateBarcode = useCallback(async (barcode: string) => {
    const result: Result = {
      title: `Barcode: ${barcode}`,
    };

    try {
      if (!barcode) {
        result.status = "warning";
        result.title = "Empty barcode";
        return result;
      }

      const { data } = await axios.post<Response>(
        "/verifyPcr",
        {
          labOrderNumber: barcode,
        }
      );

      const {
        icon,
        message,
        isSuccess,
        name,
        requisitionNumber,
        dateOfBirth,
        orderCreated,
        printNewLabel,
        testTypeName,
        printerName,
      } = data;

      result.status = icon === "success" ? "success" : "danger";
      result.message = message as string;

      if (isSuccess && printNewLabel) {
        await print(printerName, {
          requisitionNumber,
          name,
          dateOfBirth,
          testTypeName,
        });
      }
    } catch (error) {
      result.status = "warning";
      result.message = "An unexpected error occurred";
    }

    return result;
  }, []);

  return validateBarcode;
}

async function print(printerName, model) {
  try {
    const printer = labelPrinting.factory();
    await printer.printModel(
      {
        requisitionNumber: model.requisitionNumber,
        date: dateOfBirthDateParser({ date: model.dateOfBirth }),
        name: model.name,
        info: "MDR-NYC",
        testTypeName: model.testTypeName,
      },
      {
        printerName,
      }
    );
  } catch (error) {
    alert(error);
  }
}

export default useBarcode;
