import { EuiFieldText, EuiFormRow } from "@elastic/eui";
import { UseFieldType } from "modules/common/hooks/useField";

interface ScanFieldProps extends UseFieldType {
  inputRef?: React.MutableRefObject<HTMLInputElement>;
  onPressEnter: (value: string) => void;
}

function ScanField({
  inputRef,
  state,
  onChange,
  onPressEnter,
}: ScanFieldProps) {
  const onKeyPress = (e: React.KeyboardEvent) => {
    if (e.key !== "Enter") return;
    onChange("");
    onPressEnter(state.value as string);
  };

  return (
    <EuiFormRow
      fullWidth
      label={state.title}
      helpText={state?.error?.message || ""}
    >
      <EuiFieldText
        inputRef={inputRef}
        fullWidth
        placeholder={state?.placeholder}
        prepend="Barcode"
        isLoading={state?.loading}
        disabled={state?.disabled}
        value={state?.value as string}
        onChange={(e) => onChange(e.target.value)}
        onKeyPress={onKeyPress}
      />
    </EuiFormRow>
  );
}

export default ScanField;
