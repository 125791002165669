import { useEffect, useState } from "react";
import useParams from "./useParams";

export const API_KEY = "apiKey";

const useApiKey = () => {
  const [apiKey, setApiKey] = useState("");
  const params = useParams();

  useEffect(() => {
    const key = params.get(API_KEY) as string;

    if (!key) return;

    localStorage.setItem(API_KEY, key);
    setApiKey(key);
  }, []);

  return apiKey;
};

export default useApiKey;
