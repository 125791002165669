import { LabelPrinter } from "modules/services/label-printing";
import prepareLabel from "./labelGenerator";
import { printLabel } from "./labelPrinter";

export * from "./QZPrintProvider";

export const qzLabelPrinter: LabelPrinter = {
  printModel: async (model: any, settings: any) => {
    const label = await prepareLabel(model);
    await printLabel(label, settings);
  },
  previewModel: async (model: any) => {
    throw new Error("Not implemented");
  },
};
